@import '../utils/mixins/vertical-align';

.search-field {
  position: relative;

  &:focus-within {
    z-index: 1;
  }
}

.search-field__input.search-field__input {
  padding-left: 40px;
  padding-right: 40px;
}

.search-field__input--no-border.search-field__input--no-border {
  border: none;
  border-radius: 0;
}

.search-field__icon {
  @include vertical-align();

  left: 15px;
  color: #6c757d;
}

.search-field__close {
  @include vertical-align();

  right: 10px;
  cursor: pointer;
}
