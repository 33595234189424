@import '../utils/base';
@import '../utils/mixins/horizontal-align';

.app-update-banner.app-update-banner {
  @include horizontal-align();

  position: fixed;
  top: $headerHeight - 25px;
  padding: 0 4rem 0 0;
  z-index: 1040;
  margin: 0;
  color: var(--text-color);
  text-align: center;
  width: 700px;
  max-width: calc(100% - 30px);
  box-shadow: 0 0 1rem var(--brand-color);
}
