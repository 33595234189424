@import '../../utils/base';

.doughnut-chart-legend {
  list-style-type: none;
  padding: 0;
  margin: 0;

  @media (max-width: $smMax) {
    margin-top: 1rem;
  }
}

.doughnut-chart-legend__item:not(:first-child) {
  margin-top: .3rem;
}

.doughnut-chart-legend__item-color {
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin-right: 5px;
  border-radius: 10px;
}

.doughnut-chart-legend__item-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
