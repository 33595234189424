@import '../../utils/base';
@import '../../utils/mixins/vertical-align';

.short-urls-row__cell.short-urls-row__cell {
  vertical-align: middle !important;
}

.short-urls-row__cell--break {
  word-break: break-all;
}

.short-urls-row__cell--relative {
  position: relative;
}

.short-urls-row__copy-hint {
  @include vertical-align(translateX(10px));

  box-shadow: 0 3px 15px rgba(0, 0, 0, .25);

  @media (max-width: $responsiveTableBreakpoint) {
    @include vertical-align(translateX(calc(-100% - 20px)));
  }
}
