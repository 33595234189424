.edit-tag-modal__color-picker-toggle {
  cursor: pointer;
}

.edit-tag-modal__color-icon {
  color: #fff;
}

.edit-tag-modal__popover.edit-tag-modal__popover {
  border-radius: .6rem;
}
