/* stylelint-disable no-descending-specificity */

@import '../utils/mixins/vertical-align';

.dropdown-btn__toggle.dropdown-btn__toggle,
.dropdown-btn__toggle.dropdown-btn__toggle:not(:disabled):not(.disabled).active,
.dropdown-btn__toggle.dropdown-btn__toggle:not(:disabled):not(.disabled):active,
.dropdown-btn__toggle.dropdown-btn__toggle:not(:disabled):not(.disabled):focus,
.dropdown-btn__toggle.dropdown-btn__toggle:not(:disabled):not(.disabled):hover,
.show > .dropdown-btn__toggle.dropdown-btn__toggle.dropdown-toggle {
  text-align: left;
  color: var(--input-text-color);
  background-color: var(--primary-color);
  border-color: var(--input-border-color);
}

.card .dropdown-btn__toggle.dropdown-btn__toggle,
.card .dropdown-btn__toggle.dropdown-btn__toggle:not(:disabled):not(.disabled).active,
.card .dropdown-btn__toggle.dropdown-btn__toggle:not(:disabled):not(.disabled):active,
.card .dropdown-btn__toggle.dropdown-btn__toggle:not(:disabled):not(.disabled):focus,
.card .dropdown-btn__toggle.dropdown-btn__toggle:not(:disabled):not(.disabled):hover,
.show > .card .dropdown-btn__toggle.dropdown-btn__toggle.dropdown-toggle {
  background-color: var(--input-color);
}

.dropdown-btn__toggle.dropdown-btn__toggle.disabled,
.dropdown-btn__toggle.dropdown-btn__toggle:disabled {
  background-color: var(--input-disabled-color);
}

.dropdown-btn__toggle.dropdown-btn__toggle:after {
  @include vertical-align();

  right: .75rem;
}
