@import '../base';

@mixin sticky-cell($with-separators: true) {
  z-index: 1;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    bottom: -1px;
    right: if($with-separators, -1px, 0);
    background: var(--table-border-color);
    z-index: -2;
  }

  &:first-child:before {
    left: if($with-separators, -1px, 0);
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: if($with-separators, 1px, 0);
    bottom: 0;
    right: 0;
    background: var(--primary-color);
    z-index: -1;
  }

  &:first-child:after {
    left: 0;
  }
}
