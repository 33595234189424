@import '../../utils/base';

.highlight-card.highlight-card {
  text-align: center;
  border-top: 3px solid var(--brand-color);
  color: inherit;
  text-decoration: none;
}

.highlight-card__link-icon {
  position: absolute;
  right: 5px;
  bottom: 5px;
  opacity: 0.1;
  transform: rotate(-45deg);
}

.highlight-card__title {
  text-transform: uppercase;
  color: $textPlaceholder;
}
